<script setup lang="ts">
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import DrawerBankPad from '/~/components/drawer/components/payment-methods/forms/drawer-bank-pad.pure.vue'

const props = defineProps<{
  dictionary: Record<string, string>
  htmlTemplate: string
  onCancel?: () => void
  onSubmit?: () => void | Promise<void>
}>()

const emit = defineEmits<{
  (event: 'hide'): void
}>()

function onCancel() {
  props.onCancel?.()
  emit('hide')
}
</script>

<template>
  <base-aside-page title="Add Payment Method" no-padding :back="onCancel">
    <drawer-bank-pad
      :html-template="htmlTemplate"
      :dictionary="dictionary"
      :on-cancel="onCancel"
      :on-submit="onSubmit"
    />
  </base-aside-page>
</template>
