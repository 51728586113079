<script setup lang="ts">
import Mustache from 'mustache'
import { computed, ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useCms } from '/~/composables/cms/use-cms'

const props = withDefaults(
  defineProps<{
    dictionary: Record<string, string>
    htmlTemplate: string
    onCancel?: () => void
    onSubmit?: () => void | Promise<void>
  }>(),
  {
    onCancel: () => {},
    onSubmit: () => {},
  }
)

const { isDarkThemeForEwallet } = useCms()

const submitting = ref(false)

const output = computed(() =>
  Mustache.render(props.htmlTemplate, props.dictionary)
)

async function submit() {
  submitting.value = true

  await props.onSubmit()

  submitting.value = false
}
</script>

<template>
  <div
    class="relative -mb-5 flex grow flex-col items-center justify-between"
    :style="{
      '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
    }"
  >
    <div class="w-full p-5">
      <div class="pad-content" v-html="output" />
    </div>

    <div class="sticky bottom-0 w-full border-t bg-white px-5">
      <div class="my-4 grid grid-cols-2 gap-x-2">
        <base-button
          data-testid="button-cancel"
          class="w-full"
          look="outlined-color"
          :disabled="submitting"
          @click="onCancel"
        >
          Cancel
        </base-button>
        <base-button
          data-testid="button-submit"
          class="w-full"
          :disabled="submitting"
          :loading="submitting"
          @click="submit"
        >
          Confirm
        </base-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.pad-content {
  @apply text-eonx-neutral-600;

  h3 {
    @apply mb-6 text-xl text-eonx-neutral-800;
  }
  h4 {
    @apply mb-3 font-bold text-eonx-neutral-800;
  }
  p {
    @apply text-default;

    & + p {
      @apply mt-5;
    }
  }

  ol {
    @apply -mb-4 border-b pb-6;

    li + li {
      @apply mt-3;
    }
  }

  hr {
    @apply my-6;
  }
}
</style>
